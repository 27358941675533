import { isMobile } from "./utils";
import { STATE, container } from "./global";

function buildRingUI(world) {
  const scaleRange = document.querySelector("#scale-range");
  scaleRange.min = world.ring.params.scaleMin;
  scaleRange.max = world.ring.params.scaleMax;
  scaleRange.value = world.ring.params.scale;
  scaleRange.oninput = function () {
    world.ring.params.scale = this.value;
  };

  const ul = document.querySelector("#carousel-ring ul");
  for (let i = 0; i < ul.children.length; i++) {
    const li = ul.children[i];
    li.onclick = () => {
      world.ring.loadRing(li.id);
      document.querySelector(".selected").className = "";
      li.children[0].children[0].className = "selected";
    };
  }
  document.getElementById("carousel-ring").classList.remove("hidden");
  document.querySelector("#change-finger").classList.remove("hidden");
  document.querySelector("#change-finger").onclick = () => {
    world.ring.fingerIncrement();
  };
  document.querySelector("#onboarding img").src = "img/ring-onboarding.gif";
  document.getElementById("instructions").innerHTML =
    "To get started, put your left or right hand in front of the camera.";
}

function buildWatchUI(world) {
  const scaleRange = document.querySelector("#scale-range");
  scaleRange.min = world.watch.params.scaleMin;
  scaleRange.max = world.watch.params.scaleMax;
  scaleRange.value = world.watch.params.scale;
  scaleRange.oninput = function () {
    world.watch.params.scale = this.value;
  };

  const ul = document.querySelector("#carousel-watch ul");
  for (let i = 0; i < ul.children.length; i++) {
    const li = ul.children[i];
    li.onclick = () => {
      world.watch.loadWatch(li.id);
      document.querySelector(".selected").className = "";
      li.children[0].children[0].className = "selected";
    };
  }
  document.getElementById("carousel-watch").classList.remove("hidden");

  document.querySelector("#onboarding img").src = "img/watch-onboarding.gif";

  document.querySelector("#tracking-ux img").src = "img/watch-onboarding.png";
  document.querySelector("#tracking-ux").style.backgroundColor = "#ffffff";
}

function buildGloveUI(world) {
  const scaleRange = document.querySelector("#scale-range");
  scaleRange.min = world.glove.params.scaleMin;
  scaleRange.max = world.glove.params.scaleMax;
  scaleRange.value = world.glove.params.scale;
  scaleRange.oninput = function () {
    world.glove.params.scale = this.value;
  };

  const ul = document.querySelector("#carousel-glove ul");
  for (let i = 0; i < ul.children.length; i++) {
    const li = ul.children[i];
    li.onclick = () => {
      world.glove.loadGlove(li.id);
      document.querySelector(".selected").className = "";
      li.children[0].children[0].className = "selected";
    };
  }
  document.getElementById("carousel-glove").classList.remove("hidden");

  // document.querySelector("#onboarding img").src = "img/watch-onboarding.gif";

  // document.querySelector("#tracking-ux img").src = "img/watch-onboarding.png";
  document.querySelector("#tracking-ux").style.backgroundColor = "#ffffff";
  document.getElementById("instructions").innerHTML =
    "To get started, put your left or right hand in front of the camera.";
}
function buildGeneralUI() {
  const loading = document.querySelector("#loading");
  loading.parentNode.removeChild(loading);

  const okButton = document.querySelector(".confirm");
  okButton.onclick = () => {
    document.querySelector("#onboarding").className = "hidden";
    document.querySelector("#sidebar").className = "";
    STATE.onboardingConfirmed = true;
  };

  if (!isMobile()) {
    document.getElementById("device-selection").classList.add("hidden");
  }
}

function updateRingUI(world, hand) {
  const trackingUX = document.getElementById("tracking-ux");
  if (STATE.onboardingConfirmed && !world.ring.visible && hand) {
    trackingUX.classList.remove("hidden");
  } else {
    trackingUX.classList.add("hidden");
  }
}

function updateWatchUI(world, hand) {
  const trackingUX = document.getElementById("tracking-ux");
  // if (STATE.frontFacing == true) {
  if (true) {
    if (STATE.onboardingConfirmed && !hand) {
      trackingUX.classList.remove("hidden");
    } else {
      trackingUX.classList.add("hidden");
    }
  } /*else {
    if (STATE.onboardingConfirmed && !world.watch.steady) {
      trackingUX.classList.remove("hidden");
      container.classList.add("hidden");
    } else {
      trackingUX.classList.add("hidden");
      container.classList.remove("hidden");
    }
  }*/
}

export {
  buildRingUI,
  buildWatchUI,
  buildGloveUI,
  buildGeneralUI,
  updateRingUI,
  updateWatchUI,
};
