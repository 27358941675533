import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { setupModel } from "./setupModel.js";
import {
  Group,
  CylinderBufferGeometry,
  MeshStandardMaterial,
  Mesh,
} from "three";
import { Vector3, ArrowHelper } from "three";
import { pp } from "./positioning";

async function loadWatch(world) {
  const group = new Group();

  group.world = world;

  group.backParams = {
    watchPos_x: 0.16,
    watchPos_y: 0.04,
    watchPos_z: -0.04,
    cylinderPos_x: 0,
    cylinderPos_y: -0.006,
    cylinderPos_z: 0.025,
    watchScale: 19,
    cylinderSquash: 0.78,
    showCylinder: false,
    showArrow: false,
    rotateDeg1: -10,
    rotateDeg2: 4,
    enableWristRotation: true,
    scale: 69,
    scaleMin: 40,
    scaleMax: 80,
    frontendScaleFactor: 0.01,
  };

  group.frontParams = {
    watchPos_x: 0.16,
    watchPos_y: 0.04,
    watchPos_z: -0.04,
    cylinderPos_x: 0,
    cylinderPos_y: 0,
    cylinderPos_z: 0.025,
    watchScale: 19,
    cylinderSquash: 0.78,
    showCylinder: false,
    showArrow: false,
    rotateDeg1: -23,
    rotateDeg2: -1,
    enableWristRotation: true,
    scale: 69,
    scaleMin: 40,
    scaleMax: 80,
    frontendScaleFactor: 0.01,
  };

  group.params =
    group.world.facing == "environment" ? group.backParams : group.frontParams;

  const geometry = new CylinderBufferGeometry(1, 1, 2, 16, 16);
  const material = new MeshStandardMaterial({ color: "red" });
  group.cylinder = new Mesh(geometry, material);
  group.cylinder.renderOrder = Number.MIN_SAFE_INTEGER;
  group.add(group.cylinder);

  group.loadWatch = async (watchFile) => {
    group.cylinder.children.pop();
    const loader = new GLTFLoader();
    const watchData = await loader.loadAsync(watchFile);
    group.watch = setupModel(watchData);
    group.watch.rotation.y = Math.PI / 2;
    group.watch.rotation.z = Math.PI / 2;
    group.cylinder.add(group.watch);
  };

  await group.loadWatch("tryon-assets/watch/rado_export.glb");

  group.update = (hand) => {
    group.params =
      group.world.facing == "environment"
        ? group.backParams
        : group.frontParams;
    if (!group.watch) return null;
    group.watch.position.x = group.params.watchPos_x;
    group.watch.position.y = group.params.watchPos_y;
    group.watch.position.z = group.params.watchPos_z;
    group.watch.scale.set(
      group.params.watchScale,
      group.params.watchScale,
      group.params.watchScale
    );
    group.watch.scale.x =
      group.watch.scale.x * (1 - 2 * (hand.rightHandedness > 0.5));

    const cylinderScale =
      new Vector3().subVectors(hand.joints[5], hand.joints[13]).length() *
      group.params.scale *
      group.params.frontendScaleFactor;
    group.cylinder.scale.x = cylinderScale * group.params.cylinderSquash;
    group.cylinder.scale.y = cylinderScale;
    group.cylinder.scale.z = cylinderScale;
    group.watch.scale.z = group.watch.scale.z / group.params.cylinderSquash;

    group.cylinder.position.x = group.params.cylinderPos_x;
    group.cylinder.position.y = group.params.cylinderPos_y;
    group.cylinder.position.z = group.params.cylinderPos_z;

    group.cylinder.material.colorWrite = group.params.showCylinder;

    group.cylinder.rotation.x = Math.PI / 2;

    const w = new Vector3().addVectors(hand.joints[5], hand.joints[13]);
    w.multiplyScalar(0.5);
    const z = new Vector3(0, 0, 1);
    const w_pp = pp(w, z, hand.joints[0]);

    const wristDirection = new Vector3().subVectors(
      hand.joints[0],
      pp(w, z, w_pp)
    );
    const positionVector = new Vector3().addVectors(
      hand.joints[0],
      wristDirection.clone().multiplyScalar(0.65)
    );
    group.position.copy(positionVector);

    group.lookAt(w_pp);

    const x = new Vector3(1, 0, 0);
    group.rotateOnAxis(x, (2 * Math.PI * group.params.rotateDeg2) / 360);

    const a = new Vector3()
      .subVectors(hand.joints[0], hand.joints[5])
      .normalize();
    const b = new Vector3()
      .subVectors(hand.joints[13], hand.joints[5])
      .normalize();

    const c = b.cross(a);
    const arrow = new ArrowHelper(c, hand.joints[0], 0.1);

    if (group.params.enableWristRotation) {
      const O = new Vector3(0, 0, 0);
      const wristDirectionNorm = new Vector3().copy(wristDirection);
      wristDirectionNorm.normalize();
      const c_pp = pp(c, wristDirectionNorm, O);
      c_pp.normalize();
      const orientation_c_pp_z = Math.sign(
        new Vector3().crossVectors(z, c_pp).y
      );
      let angleToRotate =
        orientation_c_pp_z * c_pp.angleTo(z) -
        (2 * Math.PI * group.params.rotateDeg1) / 360;

      // correct rotation when vertical gradient of wrist is negative
      if (
        new Vector3()
          .subVectors(w_pp, positionVector)
          .dot(new Vector3(1, 0, 0)) > 0
      ) {
        angleToRotate += Math.PI;
      }

      group.rotateOnAxis(z, angleToRotate);
    }

    if (group.params.showArrow) {
      // if (scene.children.length > 0) {
      //   for (let i = scene.children.length - 1; i >= 0; i--) {
      //     if (scene.children[i].type == "ArrowHelper") {
      //       scene.children.splice(i, 1);
      //     }
      //   }
      // }
      // scene.add(arrow);
    }

    // check the hand rotation so that the vis can be disabled.
    const handRotation = c.angleTo(new Vector3(0, 0, 1));
    if (Math.abs(handRotation) > 0.9) {
      // excessively rotated
      group.steady = false;
    } else {
      group.steady = true;
    }
  };

  group.setupDatgui = (gui) => {
    gui.remember(group.params);
    gui.add(group.params, "watchScale").min(0).max(30).step(0.25);
    gui
      .add(group.params, "scale")
      .min(40)
      .max(100)
      .step(1)
      .name("cylinderScale");
    gui.add(group.params, "cylinderSquash").min(0).max(1).step(0.01);
    gui.add(group.params, "watchPos_x").min(-2).max(2).step(0.02);
    gui.add(group.params, "watchPos_y").min(-2).max(2).step(0.02);
    gui.add(group.params, "watchPos_z").min(-2).max(2).step(0.02);
    gui.add(group.params, "cylinderPos_x").min(-0.05).max(0.05).step(0.001);
    gui.add(group.params, "cylinderPos_y").min(-0.05).max(0.05).step(0.001);
    gui.add(group.params, "cylinderPos_z").min(-0.05).max(0.05).step(0.001);
    gui.add(group.params, "rotateDeg1").min(-180).max(180).step(1);
    gui.add(group.params, "rotateDeg2").min(-180).max(180).step(1);
    gui.add(group.params, "showArrow");
    gui.add(group.params, "showCylinder");
    gui.add(group.params, "enableWristRotation");
  };

  return group;
}

export { loadWatch };
