const videoElement = document.getElementById("video");
const videoSelect = document.getElementById("select");

async function setupCamera(back) {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    throw new Error(
      "Browser API navigator.mediaDevices.getUserMedia not available"
    );
  }

  const devices = await navigator.mediaDevices.enumerateDevices();

  let stream, config;
  if (back) {
    config = {
      video: {
        width: 640,
        height: 480,
      },
    };

    for (let i = 0; i < devices.length; i++) {
      if (devices[i].kind == "videoinput") {
        config.video.deviceId = { exact: devices[i].deviceId };
        try {
          stream = await navigator.mediaDevices.getUserMedia(config);
          if ("torch" in stream.getTracks()[0].getCapabilities()) {
            await gotStream(stream);
            return {
              stream: stream,
              facingMode: "environment",
            };
          } else {
            if (stream) {
              stream.getTracks().forEach((track) => {
                track.stop();
              });
            }
          }
        } catch (error) {
          // console.log(error);
        }
      }
    }
  }

  config = {
    video: {
      width: 640,
      height: 480,
      facingMode: "user",
    },
  };
  try {
    stream = await navigator.mediaDevices.getUserMedia(config);
    await gotStream(stream);
    return {
      stream: stream,
      facingMode: "user",
    };
  } catch (error) {
    config = {
      video: {
        width: 640,
        height: 480,
      },
    };
    stream = await navigator.mediaDevices.getUserMedia(config);
    await gotStream(stream);
    return {
      stream: stream,
      facingMode: "user",
    };
  }
}

async function gotStream(stream) {
  window.stream = stream;
  videoElement.srcObject = stream;
  await new Promise((resolve) => {
    videoElement.onloadedmetadata = () => {
      resolve();
    };
  });
  videoElement.play();
  // Must set below two lines, otherwise tfjs tracking doesn't work.
  videoElement.width = videoElement.videoWidth;
  videoElement.height = videoElement.videoHeight;
}

function drawVideo(videoElement, videoCanvas, image) {
  const videoCanvasCtx = videoCanvas.getContext("2d");
  const vw = videoElement.videoWidth;
  const vh = videoElement.videoHeight;
  const aspectRatio = vw / vh;
  if (aspectRatio < videoCanvas.width / videoCanvas.height) {
    videoCanvasCtx.clearRect(0, 0, videoCanvas.width, videoCanvas.height);
    videoCanvasCtx.drawImage(
      image,
      0,
      0,
      videoCanvas.width,
      videoCanvas.width * (videoElement.videoHeight / videoElement.videoWidth)
    );
  } else {
    videoCanvasCtx.clearRect(0, 0, videoCanvas.width, videoCanvas.height);
    videoCanvasCtx.drawImage(
      image,
      (videoCanvas.width -
        videoCanvas.height *
          (videoElement.videoWidth / videoElement.videoHeight)) /
        2,
      0,
      videoCanvas.height * (videoElement.videoWidth / videoElement.videoHeight),
      videoCanvas.height
    );
  }
}

function captureVideo(videoElement) {
  var canvas = document.createElement("canvas");
  canvas.width = videoElement.videoWidth;
  canvas.height = videoElement.videoHeight;
  var canvasContext = canvas.getContext("2d");
  canvasContext.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  return canvas;
}

export { setupCamera, drawVideo, captureVideo };
