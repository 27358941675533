import { Vector3 } from "three";

function scale(x, a, b, c, d) {
  return ((x - a) * (d - c)) / (b - a) + c;
}

/**
 * Maps coordinates from the mediapipe model output to coordinates in the three.js world.
 * @param {array} joints - Array of 21 hand keypoints.
 * @returns
 */
function unproject(joints, threeCamera, videoElement, canvas, zJointScale) {
  const vw = videoElement.videoWidth;
  const vh = videoElement.videoHeight;
  const aspectRatio = vw / vh;
  const cw = canvas.width;
  const ch = canvas.height;
  let result = [];
  for (const joint of joints) {
    let x, y, z;
    if (cw / ch > aspectRatio) {
      // in landscape
      const actualVW = ch * aspectRatio;
      x = scale(joint.x, 0, vw, -1, 1);
      y = -scale(joint.y, 0, (vh * ch) / (cw / aspectRatio), -1, 1);
      z = scale(joint.z, -zJointScale, zJointScale, 0, 1);
    } else {
      // in portrait
      const actualVH = cw / aspectRatio;
      const actualVW = ch * aspectRatio;
      x = -scale(
        joint.x,
        vw * (1 - (actualVW - cw) / 2 / actualVW),
        (vw * (actualVW - cw)) / 2 / actualVW,
        -1,
        1
      );
      y = -scale(joint.y, 0, vh, -1, 1);
      z = scale(joint.z, -zJointScale, zJointScale, 0, 1);
    }
    let v = new Vector3(x, y, z);
    v.unproject(threeCamera);
    result.push(v);
  }

  return result;
}

export { unproject };
