import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { setupModel } from "./setupModel.js";
import { Group, MeshStandardMaterial, Mesh } from "three";
import { Vector3, ArrowHelper } from "three";
import { pp } from "./positioning";

async function loadGlove(world) {
  const group = new Group();

  group.world = world;

  group.params = {
    glovePos_x: 0,
    glovePos_y: 0.02,
    glovePos_z: 0.115,
    scale: 160,
    showArrow: false,
    rotateDeg1: -18,
    rotateDeg2: 0,
    enableWristRotation: true,
    scaleMin: 120,
    scaleMax: 180,
    frontendScaleFactor: 0.1,
  };

  group.loadGlove = async (file) => {
    group.children.pop();
    const loader = new GLTFLoader();
    const data = await loader.loadAsync(file);
    group.glove = setupModel(data);
    group.glove.rotation.y = Math.PI / 2;
    group.glove.rotation.z = (2 * Math.PI) / 2;
    group.add(group.glove);
  };

  await group.loadGlove("tryon-assets/glove/03_baseball.glb");

  group.update = (hand) => {
    if (!group.glove) return null;
    group.glove.position.x = group.params.glovePos_x;
    group.glove.position.y = group.params.glovePos_y;
    group.glove.position.z = group.params.glovePos_z;

    const gloveScale =
      new Vector3().subVectors(hand.joints[5], hand.joints[13]).length() *
      group.params.scale *
      group.params.frontendScaleFactor;

    group.glove.scale.set(gloveScale, gloveScale, gloveScale);
    // group.glove.scale.x =
    //   group.glove.scale.x * (1 - 2 * (hand.rightHandedness > 0.5));

    const w = new Vector3().addVectors(hand.joints[5], hand.joints[13]);
    w.multiplyScalar(0.5);
    const z = new Vector3(0, 0, 1);
    const w_pp = pp(w, z, hand.joints[0]);

    const wristDirection = new Vector3().subVectors(
      hand.joints[0],
      pp(w, z, w_pp)
    );
    const positionVector = new Vector3().addVectors(
      hand.joints[0],
      wristDirection.clone().multiplyScalar(0.65)
    );
    group.position.copy(positionVector);

    group.lookAt(w_pp);

    const x = new Vector3(1, 0, 0);
    group.rotateOnAxis(x, (2 * Math.PI * group.params.rotateDeg2) / 360);

    const a = new Vector3()
      .subVectors(hand.joints[0], hand.joints[5])
      .normalize();
    const b = new Vector3()
      .subVectors(hand.joints[13], hand.joints[5])
      .normalize();

    const c = b.cross(a);
    const arrow = new ArrowHelper(c, hand.joints[0], 0.1);

    if (group.params.enableWristRotation) {
      const O = new Vector3(0, 0, 0);
      const wristDirectionNorm = new Vector3().copy(wristDirection);
      wristDirectionNorm.normalize();
      const c_pp = pp(c, wristDirectionNorm, O);
      c_pp.normalize();
      const orientation_c_pp_z = Math.sign(
        new Vector3().crossVectors(z, c_pp).y
      );
      let angleToRotate =
        orientation_c_pp_z * c_pp.angleTo(z) -
        (2 * Math.PI * group.params.rotateDeg1) / 360;

      // correct rotation when vertical gradient of wrist is negative
      if (
        new Vector3()
          .subVectors(w_pp, positionVector)
          .dot(new Vector3(1, 0, 0)) > 0
      ) {
        angleToRotate += Math.PI;
      }

      group.rotateOnAxis(z, angleToRotate);
    }

    if (group.params.showArrow) {
      // if (scene.children.length > 0) {
      //   for (let i = scene.children.length - 1; i >= 0; i--) {
      //     if (scene.children[i].type == "ArrowHelper") {
      //       scene.children.splice(i, 1);
      //     }
      //   }
      // }
      // scene.add(arrow);
    }

    // check the hand rotation so that the vis can be disabled.
    const handRotation = c.angleTo(new Vector3(0, 0, 1));
    if (Math.abs(handRotation) > 0.9) {
      // excessively rotated
      group.steady = false;
    } else {
      group.steady = true;
    }
  };

  group.setupDatgui = (gui) => {
    gui.remember(group.params);
    gui.add(group.params, "scale").min(120).max(180).step(1);
    gui.add(group.params, "glovePos_x").min(-0.5).max(0.5).step(0.005);
    gui.add(group.params, "glovePos_y").min(-0.5).max(0.5).step(0.005);
    gui.add(group.params, "glovePos_z").min(-0.5).max(0.5).step(0.005);
    gui.add(group.params, "rotateDeg1").min(-180).max(180).step(1);
    gui.add(group.params, "rotateDeg2").min(-180).max(180).step(1);
    gui.add(group.params, "showArrow");
    gui.add(group.params, "enableWristRotation");
  };

  return group;
}

export { loadGlove };
