import {
  Group,
  EquirectangularReflectionMapping,
  MeshPhysicalMaterial,
  Vector2,
  RepeatWrapping,
  TextureLoader,
} from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

/**
 *
 * @param {loaded GLB data} data
 * @returns
 */
function setupModel(data) {
  const options = {
    enableSwoopingCamera: false,
    enableRotation: true,
    transmission: 1,
    thickness: 0.6,
    roughness: 0.05,
    envMapIntensity: 2,
    clearcoat: 0.15,
    clearcoatRoughness: 0.1,
    normalScale: 0.1,
    clearcoatNormalScale: 0.1,
    normalRepeat: 1,
    bloomThreshold: 0.85,
    bloomStrength: 0.5,
    bloomRadius: 0.33,
  };

  const hdrEquirect = new RGBELoader().load("empty_warehouse_01_1k.hdr", () => {
    hdrEquirect.mapping = EquirectangularReflectionMapping;
  });

  const textureLoader = new TextureLoader();
  const normalMapTexture = textureLoader.load("normal.jpg");
  normalMapTexture.wrapS = RepeatWrapping;
  normalMapTexture.wrapT = RepeatWrapping;
  normalMapTexture.repeat.set(options.normalRepeat, options.normalRepeat);

  const material = new MeshPhysicalMaterial({
    transmission: options.transmission,
    thickness: options.thickness,
    roughness: options.roughness,
    envMap: hdrEquirect,
    envMapIntensity: options.envMapIntensity,
    clearcoat: options.clearcoat,
    clearcoatRoughness: options.clearcoatRoughness,
    normalScale: new Vector2(options.normalScale),
    normalMap: normalMapTexture,
    clearcoatNormalMap: normalMapTexture,
    clearcoatNormalScale: new Vector2(options.clearcoatNormalScale),
  });

  const model = data.scene;

  // for (let i = 0; i < model.children.length; i++) {
  //   const child = model.children[i];
  //   if (child.name == "Icosphere") {
  //     child.material = material;
  //   }
  // }

  return model;
}

export { setupModel };
