export const STATE = {
  onboardingConfirmed: false,
  backFacing: true,
  params: {
    zScaleFactor: 0.4,
  },
};

export const videoElement = document.getElementById("video");
export const videoCanvas = document.getElementById("video-canvas");
export const container = document.getElementById("scene-container");
export const trackingUX = document.getElementById("tracking-ux");
export const deviceSelection = document.getElementById("device-selection");
