import { PointLight } from "three";

function createLights() {
  const light1 = new PointLight(0xffffff, 1);
  light1.position.set(0, 0, 10);

  const light2 = new PointLight(0xffffff, 1);
  light2.position.set(0, 7.07, 7.07);

  const light3 = new PointLight(0xffffff, 1);
  light3.position.set(0, 7.07, 7.07);

  return { light1, light2, light3 };
}

export { createLights };
