import {
  CylinderBufferGeometry,
  Group,
  Mesh,
  MeshStandardMaterial,
  Vector3,
  ArrowHelper,
  Matrix3,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { setupModel } from "./setupModel.js";
import { pp, removeArrows } from "./positioning";

/**
 * Given x, y coordinates, get the angle anticlockwise from the x-axis to that point
 * @param {number} x
 * @param {number} y
 * @returns
 */
function coordsToAngle(x, y) {
  let theta = Math.atan(y / x);

  if (x < 0) {
    theta += Math.PI;
  }

  return theta;
}

async function loadRing() {
  const group = new Group();

  group.params = {
    scale: 22,
    scaleMin: 15,
    scaleMax: 29,
    frontendScaleFactor: 0.01,
    alpha: 0.65, // scalar between 0 and 1 controlling position of ring along finger. 0=base of finger, 1=first joint.
    showCircles: false,
    showCylinder: false,
    showArrows: false,
    position: {
      x: -0.2,
      y: -0.1,
      z: -0.1,
    },
    ringPos: {
      x: 0,
      y: 0,
      z: 0,
    },
    ringScale: 1,
    finger: 3,
    debug: false,
    angle: 6,
    tilt: 20,
  };

  const geometry = new CylinderBufferGeometry(1, 1, 4, 16);
  const material = new MeshStandardMaterial({ color: "gray" });
  group.cylinder = new Mesh(geometry, material);
  group.cylinder.renderOrder = Number.MIN_SAFE_INTEGER;
  group.cylinder.material.colorWrite = false;
  group.add(group.cylinder);

  group.loadRing = async (ringFile) => {
    group.cylinder.children.pop();
    const loader = new GLTFLoader();
    const ringData = await loader.loadAsync(ringFile);
    group.ring = setupModel(ringData);
    group.ring.rotation.x = (3 * Math.PI) / 2;
    group.cylinder.add(group.ring);

    group.ring.tick = () => {};
  };

  await group.loadRing("tryon-assets/ring/gold.glb");

  group.update = (hand) => {
    group.cylinder.material.colorWrite = group.params.showCylinder;
    group.cylinder.position.set(
      group.params.position.x,
      group.params.position.y,
      group.params.position.z
    );
    group.ring.position.set(
      group.params.ringPos.x,
      group.params.ringPos.y,
      group.params.ringPos.z
    );

    group.ring.scale.set(
      group.params.ringScale,
      group.params.ringScale,
      group.params.ringScale
    );

    const baseJointNumber = 5 + (group.params.finger - 1) * 4;
    const fingerJointNumber = baseJointNumber + 1;

    let baseJoint = hand.joints[baseJointNumber];
    let fingerJoint = hand.joints[fingerJointNumber];
    const center = new Vector3().addVectors(
      new Vector3().copy(baseJoint).multiplyScalar(1 - group.params.alpha),
      new Vector3().copy(fingerJoint).multiplyScalar(group.params.alpha)
    );

    // set position
    group.position.copy(center);

    const fingerSegment = new Vector3().subVectors(fingerJoint, baseJoint);
    const fingerSegmentLength = fingerSegment.length();
    const scale =
      fingerSegmentLength *
      group.params.scale *
      group.params.frontendScaleFactor;

    // set scale
    group.scale.set(scale, scale, scale);

    // orientation of cylinder so that it's parallel along finger
    group.cylinder.rotation.x = Math.PI / 2;
    group.cylinder.rotation.y = -Math.PI / 2;
    group.cylinder.rotation.z = 0;
    group.lookAt(fingerJoint);

    const cylinderDirection = new Vector3();
    group.cylinder.getWorldDirection(cylinderDirection);
    const a = new Vector3().subVectors(hand.joints[5], hand.joints[0]);
    const b = new Vector3().subVectors(hand.joints[17], hand.joints[5]);

    // `cross` is the vector perpendicular to your hand palm, as defined
    // by your little, index, and wrist joints.
    // it tends to point outwards, we will adjust with `group.params.angle` later.
    const cross = new Vector3().crossVectors(a, b).normalize();

    const fingerSegmentNormalized = new Vector3().copy(fingerSegment);
    fingerSegmentNormalized.normalize();

    // `cross2` is the plane normal to the plane passing through the finger segment
    // and perpendicular to the palm. The ring orientation will be on this plane.
    const cross2 = new Vector3().crossVectors(cross, fingerSegmentNormalized);

    // for the direction of the ring.
    // `cylinderDirection` is already perpendicular to the finger segment.
    // projecting this onto the plane wth normal `cross2` gives the desired orientation.
    const desiredDirection = new Vector3().copy(cylinderDirection);
    desiredDirection.projectOnPlane(cross2);

    const cylinderDirectionDotCross2Sign = Math.sign(
      cylinderDirection.dot(cross2)
    );

    let angleTo =
      -cylinderDirectionDotCross2Sign *
      desiredDirection.angleTo(cylinderDirection);

    const xPosProjected = new Vector3().copy(center).project(group.camera).x;

    const tiltAngle = (-xPosProjected * group.params.tilt * 2 * Math.PI) / 360;

    // the desired direction can be inverted depending on the video feed horizontal flip.
    const orientationSign = Math.sign(cross.dot(desiredDirection));
    if (orientationSign == -1) {
      angleTo = angleTo + tiltAngle + (2 * Math.PI * group.params.angle) / 360;
    } else {
      angleTo =
        Math.PI -
        angleTo +
        tiltAngle +
        (2 * Math.PI * group.params.angle) / 360;
    }

    group.cylinder.rotateOnAxis(new Vector3(0, 1, 0), angleTo);
    // the ring is now fully positioned.

    // check the hand rotation so that the vis can be disabled.
    const handRotation = cross.angleTo(new Vector3(0, 0, 1));
    if (Math.abs(handRotation) > 0.9) {
      // excessively rotated
      // disable vis
      group.visible = false;
    } else {
      group.visible = true;
    }

    // visualisation
    if (group.params.debug) {
      const O = new Vector3(0, 0, 0);

      removeArrows(group.scene);

      const arrow1 = new ArrowHelper(cross, center, 0.1, 0xf56042); //reddish
      const arrow2 = new ArrowHelper(cross2, center, 0.1, 0xebeb0c); //yellow
      const arrow3 = new ArrowHelper(desiredDirection, center, 0.1, 0x02cc0c); // green
      const arrow4 = new ArrowHelper(cylinderDirection, center, 0.1, 0x000000); //black

      group.scene.add(arrow1, arrow2, arrow3, arrow4);
    }

    let now = new Date();
    let stage = Math.floor(now / 10000);
    if (!group.last) group.last = stage;
    if (group.last != stage) {
      group.last = stage;
    }
  };

  group.setupDatgui = (gui) => {
    gui.remember(group.params);

    gui.add(group.params, "scale").min(10).max(30).step(0.5).name("scale");
    gui.add(group.params, "alpha").min(0).max(1).step(0.01).name("finger pos");

    gui.add(group.params, "showCylinder").name("show cylinder");
    gui.add(group.params.position, "x").min(-2).max(2).step(0.1);
    gui.add(group.params.position, "y").min(-2).max(2).step(0.1);
    gui.add(group.params.position, "z").min(-2).max(2).step(0.1);

    gui
      .add(group.params, "ringScale")
      .min(100)
      .max(160)
      .step(1)
      .name("ring scale");
    gui.add(group.params.ringPos, "x").min(-2).max(2).step(0.1);
    gui.add(group.params.ringPos, "y").min(-2).max(2).step(0.1);
    gui.add(group.params.ringPos, "z").min(-2).max(2).step(0.1);
    gui.add(group.params, "debug").name("show debug vis");
    gui.add(group.params, "angle").min(-90).max(90).step(1);
    gui.add(group.params, "tilt").min(0).max(90).step(1);
  };

  group.fingerIncrement = () => {
    if (group.params.finger == 4) {
      group.params.finger = 1;
      return;
    }

    group.params.finger++;
  };

  return group;
}

export { loadRing };
