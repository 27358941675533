import { createCamera } from "./components/camera.js";
import { createScene } from "./components/scene.js";
import { createLights } from "./components/lights.js";
import { loadWatch } from "./tryons/watch.js";
import { loadRing } from "./tryons/ring.js";
import { loadGlove } from "./tryons/glove.js";
import { createCircle } from "./components/circle.js";

import { createRenderer } from "./systems/renderer.js";
import { resizer } from "./systems/Resizer.js";
import { applyHDR } from "./systems/hdr.js";

import { createComposer } from "./systems/composer.js";
import * as dat from "dat.gui";

import { urlParams } from "../utils";

let camera, renderer, scene;

const circles = [];

const updatables = [];

class World {
  /**
   * Initalised the three.js world. The init() method should be run afterwards for setup that requires async.
   * @param {dom element} container - Parent container to hold the output canvas.
   */
  constructor(container, facing) {
    this.container = container;

    this.facing = facing;

    camera = createCamera();
    scene = createScene();
    renderer = createRenderer();
    container.append(renderer.domElement);
    createComposer(scene, camera, renderer);
    resizer(container, camera, renderer);

    applyHDR(renderer, scene);
    this.handPresent = true;
    this.handGood = true;
    this.trackingLostFor = 0;

    const { light1, light2, light3 } = createLights();
    scene.add(light1, light2, light3);

    this.params = {
      nu: 0.4,
      beta: 0.055,
      smoothingMethod: "time",
      showModelVis: false,
    };
  }

  async setupRing() {
    this.ring = await loadRing();
    this.ring.scene = scene;
    this.ring.camera = camera;
    scene.add(this.ring);
    updatables.push(this.ring);
  }

  async setupWatch() {
    this.watch = await loadWatch(this);
    scene.add(this.watch);
    updatables.push(this.watch);
  }

  async setupGlove() {
    this.glove = await loadGlove();
    scene.add(this.glove);
    updatables.push(this.glove);
  }

  update() {
    for (let i = 0; i < updatables.length; ++i) {
      updatables[i].update(this.hand);
    }
    renderer.render(scene, camera);
  }

  setJoints(hand) {
    // the output from isRightHand can be unstable.
    // to mitigate this, take the average output over the last 5 frames or so.

    let rightHandedness = hand.handedness == "Right";

    if (!("rightHandedness" in hand)) {
      hand.rightHandedness = rightHandedness;
    } else {
      hand.rightHandedness =
        0.8 * this.hand.rightHandedness + 0.2 * rightHandedness;
    }

    if (hand.rightHandedness < 0.5) {
      // if left hand, first flip the joints horizontally, then flip the canvas (so the final image is correct)
      for (let i = 0; i < hand.joints.length; i++) {
        hand.joints[i].x *= -1;
      }
      renderer.domElement.className = "flipped";
    } else {
      renderer.domElement.className = "";
    }

    let now = new Date();
    if (this.hand) {
      let nu;
      if (this.params.smoothingMethod == "time") {
        let r = now - this.previousDate;
        nu = r / 1000 / this.params.beta;
        nu = Math.min(nu, 0.5);
      } else {
        nu = this.params.nu;
      }

      for (let i = 0; i < this.hand.joints.length; i++) {
        hand.joints[i].x =
          nu * hand.joints[i].x + (1 - nu) * this.hand.joints[i].x;
        hand.joints[i].y =
          nu * hand.joints[i].y + (1 - nu) * this.hand.joints[i].y;
        hand.joints[i].z =
          nu * hand.joints[i].z + (1 - nu) * this.hand.joints[i].z;
      }
    }
    this.previousDate = now;

    if (this.params.showModelVis) {
      for (let i = 0; i < hand.joints.length; i++) {
        if (circles.length < hand.joints.length) {
          circles.push(createCircle("blue"));
          circles[i].scale.set(0.002, 0.002, 0.002);
          scene.add(circles[i]);
        }
        circles[i].position.copy(hand.joints[i]);
      }
    }

    this.hand = hand;
  }

  getCamera() {
    return camera;
  }

  getCanvas() {
    return renderer.domElement;
  }

  setupDatgui() {
    this.gui = new dat.gui.GUI();

    this.gui.closed = true;

    this.gui.width = 350;

    this.gui.domElement.id = "gui";

    this.gui
      .add(this.params, "nu")
      .min(0)
      .max(1)
      .step(0.01)
      .name("smoothing (frames)");

    this.gui
      .add(this.params, "beta")
      .min(0.005)
      .max(0.25)
      .step(0.005)
      .name("smoothing (time)");

    this.gui.add(this.params, "smoothingMethod", ["frames", "time"]);

    this.gui.add(this.params, "showModelVis");
  }

  getDatgui() {
    return this.gui;
  }

  debug(debug) {
    if (debug) {
      this.setupDatgui();
      for (let i = 0; i < updatables.length; ++i) {
        updatables[i].setupDatgui(this.gui);
      }
    }
  }
}

export { World };
