import "@tensorflow/tfjs-backend-webgl";
import * as mpHands from "@mediapipe/hands";
import * as tfjsWasm from "@tensorflow/tfjs-backend-wasm";
tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`
);
import * as handdetection from "@tensorflow-models/hand-pose-detection";
import { isiOS } from "./utils";

async function createDetector() {
  const model = handdetection.SupportedModels.MediaPipeHands;

  const params = new URLSearchParams(window.location.search);

  let runtime;
  if (params.has("runtime")) {
    runtime = params.get("runtime");
  } else {
    runtime = isiOS() ? "tfjs" : "mediapipe";
  }

  const detectorConfig = {
    runtime: runtime,
    modelType: "lite",
    maxHands: 1,
    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/hands@${mpHands.VERSION}`,
  };
  const detector = await handdetection.createDetector(model, detectorConfig);
  return detector;
}

async function estimateHands(detector, videoElement, flipped) {
  let hands;
  // Detector can be null if initialization failed (for example when loading
  // from a URL that does not exist).
  if (detector != null) {
    // Detectors can throw errors, for example when using custom URLs that
    // contain a model that doesn't provide the expected output.
    try {
      hands = await detector.estimateHands(videoElement, {
        flipHorizontal: flipped,
      });
    } catch (error) {
      detector.dispose();
      detector = null;
      detector = await createDetector();
      return null;
    }
    if (hands.length > 0) {
      let hand = hands[0];
      let joints = [];
      try {
        for (let j = 0; j < 21; j++) {
          joints.push({
            x: hand.keypoints[j].x,
            y: hand.keypoints[j].y,
            z: hand.keypoints3D[j].z,
          });
        }
      } catch {
        return false;
      }
      return {
        joints: joints,
        handedness: hand.handedness,
      };
    } else {
      return false;
    }
  }
}

export { createDetector, estimateHands };
