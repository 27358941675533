import { World } from "./World/World";
import { setupCamera, captureVideo, drawVideo } from "./camera";
import Stats from "stats.js";
import { createDetector, estimateHands } from "./mediaPipeHands";
import { unproject } from "./unproject";
import {
  buildGeneralUI,
  buildWatchUI,
  buildRingUI,
  buildGloveUI,
  updateRingUI,
  updateWatchUI,
} from "./ui";
import { urlParams } from "./utils";
import {
  STATE,
  videoElement,
  videoCanvas,
  container,
  trackingUX,
  deviceSelection,
} from "./global";

let tryon;
if (urlParams.has("tryon")) {
  tryon = urlParams.get("tryon");
} else {
  tryon = "watch";
}

function resizeVideoCanvas() {
  videoCanvas.width = window.innerWidth;
  videoCanvas.height = window.innerHeight;
}

resizeVideoCanvas();

window.addEventListener("resize", resizeVideoCanvas);

let detector, world, stats, videoResults;

async function setupWorld() {
  world = new World(
    container,
    stream.getTracks()[0].getConstraints().facingMode
  );
  if (tryon == "ring") {
    await world.setupRing();
  } else if (tryon == "watch") {
    await world.setupWatch();
  } else if (tryon == "glove") {
    await world.setupGlove();
  }

  if (urlParams.has("debug")) {
    world.debug(true);
    const gui = world.getDatgui();
    gui.add(STATE.params, "zScaleFactor").min(0.1).max(1).step(0.01);
  }
}

async function getVideo() {
  if (window.stream) {
    window.stream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  videoResults = await setupCamera(STATE.backFacing);
  console.log(videoResults.facingMode);
  stream = videoResults.stream;

  if ("environment" != videoResults.facingMode) {
    videoCanvas.classList.add("flipped");
  } else {
    videoCanvas.classList.remove("flipped");
  }

  if (world) world.facing = videoResults.facingMode;

  detector = null;
  detector = await createDetector();
}

deviceSelection.onclick = () => {
  STATE.backFacing = !STATE.backFacing;
  getVideo();
};

let last;

async function renderPrediction() {
  if (videoElement.readyState < 2) {
    await new Promise((resolve) => {
      videoElement.onloadeddata = () => {
        resolve();
      };
    });
  }

  stats.begin();

  let image = captureVideo(videoElement);

  let hand = await estimateHands(
    detector,
    videoElement,
    "environment" != videoResults.facingMode
  );

  let now = new Date();
  let stage = Math.floor(now / 10000);
  if (!last) last = stage;
  if (last != stage) {
    last = stage;
  }

  if (hand) {
    let jointsWorldCoords = unproject(
      hand.joints,
      world.getCamera(),
      videoElement,
      world.getCanvas(),
      STATE.params.zScaleFactor
    );

    hand.joints = jointsWorldCoords;

    if (isNaN(hand.joints[0].x)) {
      detector = null;
      detector = await createDetector();
      return;
    }

    container.classList.remove("hidden");

    world.setJoints(hand);

    world.update();
  } else {
    container.classList.add("hidden");
    trackingUX.classList.add("hidden");
  }

  if (tryon == "ring") {
    updateRingUI(world, hand);
  } else if (tryon == "watch") {
    updateWatchUI(world, hand);
  } else if (tryon == "glove") {
    //
  }

  drawVideo(videoElement, videoCanvas, image);

  stats.end();

  requestAnimationFrame(() => {
    renderPrediction();
  });
}

async function main() {
  await getVideo();

  await setupWorld();

  stats = new Stats();
  stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
  if (urlParams.has("debug")) {
    document.body.appendChild(stats.dom);
  }

  // detector = await createDetector();

  if (tryon == "ring") {
    buildRingUI(world);
  } else if (tryon == "watch") {
    buildWatchUI(world);
  } else if (tryon == "glove") {
    buildGloveUI(world);
  }

  buildGeneralUI();

  renderPrediction();
}

main();
