import { Vector3 } from "three";

/**
 *
 * @param {Vector3} P - point to project
 * @param {Vector3} n - normalised vector perpendicular to plane
 * @param {Vector3} Q - point through the plane
 * @returns
 */
function pp(P, n, Q) {
  let a = new Vector3();
  a.subVectors(P, Q);
  let c = a.dot(n);
  let r = new Vector3();
  r.copy(n);
  r.multiplyScalar(-c);
  r.add(P);
  return r;
}

function removeArrows(group) {
  if (group.children.length > 0) {
    for (let i = group.children.length - 1; i >= 0; i--) {
      if (group.children[i].type == "ArrowHelper") {
        group.children.splice(i, 1);
      }
    }
  }
}

export { pp, removeArrows };
